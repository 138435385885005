import { Helmet } from 'react-helmet'
import { Grid, Heading, Text } from 'theme-ui'

import { BaseLayout } from '../layout/base'
import { Footer } from '../components/Footer'
import { Section } from '../components/Section'
import { Container } from '../components/Container'

const AboutPage = () => {
  return (
    <BaseLayout>
      <Helmet>
        <title>O společnosti | Privy</title>
      </Helmet>

      <main>
        <Section
          sx={{
            backgroundColor: 'white',
          }}
        >
          <Container>
            <Grid
              sx={{
                py: [4, null, null, 16],
                gap: 8,
              }}
            >
              <Heading
                as="h1"
                sx={{
                  fontSize: ['2xl', '4xl', '5xl', '6xl'],
                }}
                data-sal="slide-left"
                data-sal-duration="300"
                data-sal-delay="100"
                data-sal-easing="ease"
              >
                O společnosti
              </Heading>

              <Text
                as="p"
                sx={{ fontSize: ['lg', null, null, 'xl'] }}
                data-sal="fade"
                data-sal-duration="300"
                data-sal-delay="100"
                data-sal-easing="ease"
              >
                Již <strong>10 let usilovně pracujeme</strong> s jasným cílem, a
                to jak pomoc vyřešit tíživé situace lidem, kteří neprojdou
                schválením úvěrů, případně mají vícero věřitelů. Náš cíl bylo
                vymyslet produkt, který těmto lidem pomůže bez toho aniž by
                museli opustit svou nemovitost a nebo o ní nepřišli. Nyní se vše
                stává skutečností a nabízíme řešení všem, kteří chtěji zůstat ve
                svém.
              </Text>

              <Heading
                as="h2"
                sx={{
                  color: 'brandPrimary.500',
                  fontSize: ['2xl', '2xl', '3xl', '4xl'],
                  textAlign: ['center', null, null, 'start'],
                }}
                data-sal="slide-right"
                data-sal-duration="300"
                data-sal-delay="100"
                data-sal-easing="ease"
              >
                Prodejte nemovitost a zůstaňte ve&nbsp;svém.
              </Heading>
            </Grid>
          </Container>
        </Section>
      </main>

      <Footer />
    </BaseLayout>
  )
}

export default AboutPage
